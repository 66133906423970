@tailwind base;
@tailwind components;
@tailwind utilities;
@import '/font/stylesheet.css';

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 800;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
}

h1,
h2,
h3,
h4 {
  font-weight: 800;
}

h1 sub,
h2 sub,
h3 sub {
  font-size: 45%;
}

button {
  font-weight: 500;
}
button * {
  font-weight: 500;
}

.cat-dark {
  background: url(/img/xp-pattern.png), #000000;
}

.gender-card-container > :first-child {
  margin-left: auto;
}

/* Classi per componente Immagine */

.custom-img {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}

.unset-img {
  width: 100%;
}
.unset-img > div {
  position: unset !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

/* Carousel Reviews */

.review-carousel {
  background-color: #f7f7f7;
}

.review-carousel > .react-multi-carousel-track > .react-multi-carousel-item {
  opacity: 0.3;
}

.review-carousel
  > .react-multi-carousel-track
  > .react-multi-carousel-item--active {
  opacity: 1;
}

.review-carousel > .react-multiple-carousel__arrow--right {
  right: 45%;
  bottom: 5rem;
  background-color: white;
}

.review-carousel > .react-multiple-carousel__arrow--right::before {
  color: #212121;
}

.review-carousel > .react-multiple-carousel__arrow--left::before {
  color: #212121;
}

.review-carousel > .react-multiple-carousel__arrow--left {
  left: 45%;
  bottom: 5rem;
  background-color: white;
}

@media only screen and (max-width: 1023px) {
  .review-carousel > .react-multiple-carousel__arrow--right {
    right: 35%;
  }
  .review-carousel > .react-multiple-carousel__arrow--left {
    left: 35%;
  }
}

@media only screen and (max-width: 464px) {
  .review-carousel > .react-multiple-carousel__arrow--right {
    right: 25%;
  }
  .review-carousel > .react-multiple-carousel__arrow--left {
    left: 25%;
  }
}

.lightbox-carousel > .react-multiple-carousel__arrow--right {
  background-color: white;
  -webkit-box-shadow: 1px 1px 3px 3px rgba(127, 127, 127, 0.1);
  box-shadow: 1px 1px 3px 3px rgba(127, 127, 127, 0.1);
}

.lightbox-carousel > .react-multiple-carousel__arrow--right::before,
.lightbox-carousel > .react-multiple-carousel__arrow--left::before {
  color: #212121;
  font-size: 18px;
}

.lightbox-carousel > .react-multiple-carousel__arrow--left {
  background-color: white;
  -webkit-box-shadow: 1px 1px 3px 3px rgba(127, 127, 127, 0.1);
  box-shadow: 1px 1px 3px 3px rgba(127, 127, 127, 0.1);
}

/* Carousel sport */

.sport-carousel > .react-multiple-carousel__arrow--right,
.sport-carousel > .react-multiple-carousel__arrow--left {
  background-color: white;
}

.sport-carousel > .react-multiple-carousel__arrow--right::before,
.sport-carousel > .react-multiple-carousel__arrow--left::before {
  color: #212121;
}

/* My Carousel */

.my-carousel > .react-multiple-carousel__arrow--right,
.my-carousel > .react-multiple-carousel__arrow--left {
  background-color: white;
}

.my-carousel > .react-multiple-carousel__arrow--right::before,
.my-carousel > .react-multiple-carousel__arrow--left::before {
  color: #212121;
}

.my-carousel
  > .react-multiple-carousel__arrow:hover
  .react-multiple-carousel__arrow--right::before,
.my-carousel
  > .react-multiple-carousel__arrow:hover
  .react-multiple-carousel__arrow--left::before {
  color: #ffffff;
}

/* Carousel product detail */

.react-multi-carousel-dot-list {
  bottom: 20px !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
}

/* Spinner */
.spinner {
  border-top-color: #212121;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
  width: 3em;
  height: 3em;
  margin-left: auto;
  margin-right: auto;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* height */

.min-h-90vh {
  min-height: 90vh;
}

.bottom-0 {
  bottom: 0;
}

.min-h-60vh {
  min-height: 60vh;
}

@media only screen and (max-width: 767px) {
  .min-h-60vh {
    min-height: 100vh;
  }
}

.height-80vh {
  height: 80vh;
}

.width-80vh {
  max-width: 80vh;
}

.h-50vh {
  height: 50vh;
}

.h-600 {
  height: 600px;
}

.h-400 {
  height: 400px;
}

.h-350 {
  height: 350px;
}

/* text-shadow */

.text-shadow {
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
}

.bg-blur {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.foreground {
  backdrop-filter: blur(5px);
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.3);
}

/* font-size */

.text-9px {
  font-size: 9px;
}

.text-16px {
  font-size: 16px;
}

/* table size guide */

.table-sizeguide {
  width: 100%;
  font-size: 0.875rem;
}

.table-sizeguide thead tr th {
  text-align: center;
  padding: 0.25rem 0.75rem;
  border-top: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: normal;
}

.table-sizeguide thead tr th:nth-child(2),
.table-sizeguide thead tr th:nth-child(4),
.table-sizeguide thead tr th:last-child {
  background-color: #1c1e2b;
}

.table-sizeguide thead tr th:nth-child(3),
.table-sizeguide thead tr th:nth-child(5) {
  background-color: #718098;
}

.table-sizeguide tbody tr td {
  text-align: center;
  padding: 0.3rem 0.75rem;
  border: 1px solid #e5e5e5;
}

.table-sizeguide tbody tr td:first-child {
  text-align: left;
}

/* community card */

.community-card {
  width: 210px;
  min-width: 210px;
  height: 210px;
}

.community-card-last::after {
  content: '';
  position: relative;
  right: -105px;
  width: 1.5rem;
  height: 210px;
}

.community-scroll::-webkit-scrollbar {
  height: 0px;
}

/* WP-CONTENT */
.page-container {
  max-width: 1440px;
}

.wpb_animate_when_almost_visible {
  opacity: 1 !important;
}

@media only screen and (max-width: 375px) {
  .newsletter-custom-mobile > form {
    flex-direction: column;
    align-items: flex-start;
    border: none;
    padding-left: 0;
  }
  .newsletter-custom-mobile > form > input {
    width: 100%;
    border: 1px solid rgba(156, 163, 175, 0.6);
    border-radius: 4px;
  }
  .newsletter-custom-mobile > form > input:focus {
    outline: none !important;
    --tw-ring-color: transparent !important;
    border-color: rgba(156, 163, 175, 0.6) !important;
  }
  .newsletter-custom-mobile > form > button {
    margin-top: 16px;
  }
}

.shop__card::before {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(
    0deg,
    rgba(34, 39, 53, 0.65) 0,
    rgba(34, 39, 53, 0) 50%,
    rgba(34, 39, 53, 0) 100%
  );
  opacity: 0.82;
  pointer-events: none;
}

/* /Shop - card */
.shop__card img {
  width: 100%;
}

.rc-anchor,
.rc-anchor-alert,
.grecaptcha-badge {
  display: none !important;
}
